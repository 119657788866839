import React from 'react';
import { graphql } from 'gatsby';
import { Link } from '@chakra-ui/react';

import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Layout from '../../../components/atoms/layout';
import PageTitle from '../../../components/PageTitle';
import { Question } from '../../../template/legal/styles';
import TermOfUseProps from '../../../interface/template';
import { Text2, Text3 } from '../../../components/atoms/body';
import withI18next from '../../../i18n/TemplateI18n';

const PrivacyPolicy: React.SFC<any> = (props: TermOfUseProps) => {
  const { pageContext } = props;
  const { url, pageName } = pageContext;

  return (
    <>
      <Header pageName={pageName} showBackground url={url}>
        <PageTitle subtitle="" title="Privacy Policy" />
      </Header>
      <Layout>
        <Question>
          <Text3 as="p">
            <strong>POLÍTICA DE PRIVACIDADE</strong>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">IDENTIFICAÇO</Text2>
          <Text3 as="p">
            <ul>
              <li>
                <strong>Responsável pelo tratamento</strong> Ironhack Group INC
                Sucursal em Espanha (doravante, “Ironhack”)
              </li>
              <li>
                <strong>NIF: </strong>W4009352H
              </li>
              <li>
                <strong>Sede social</strong>: C/ CASTELLO, 115, BAJOS 7 - 28006
                MADRID
              </li>
              <li>
                <strong>Delegado de Proteção de Dados (DPO)</strong>: O
                Utilizador pode contactar o DPO através de carta dirigida à sede
                social da Ironhack ao cuidado do “Delegado de Proteção de Dados”
                ou através do correio eletrónico{' '}
                <a href="mailto:legal@ironahack.com">legal@ironahack.com</a>
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">INFORMAÇO E CONSENTIMENTO</Text2>
          <Text3 as="p">
            Mediante a aceitação da presente Política de Privacidade, o
            utilizador fica informado e dá o seu consentimento informado,
            expresso, livre e inequívoco a que os dados pessoais que sejam
            fornecidos através dos formulários da página Web localizada no URL{' '}
            <a href="https://www.ironhack.com/pt">www.ironhack.com</a>{' '}
            (doravante, o “Website”) sejam tratados pela Ironhack.
          </Text3>
          <Text3 as="p">
            A Ironhack não será responsável pelos tratamentos de dados que sejam
            efetuados através de websites que não sejam responsabilidade da
            Ironhack.{' '}
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">OBRIGATORIEDADE DE FACILITAR OS DADOS</Text2>
          <Text3 as="p">
            Os dados solicitados nos formulários do Website são, com caráter
            geral, obrigatórios (exceto se no campo requerido se especificar o
            contrário) para cumprir as finalidades estabelecidas. Portanto, caso
            não se facilitem corretamente, não se poderão satisfazer os pedidos
            efetuados pelo utilizador, sem prejuízo de que se possa visualizar
            livremente o conteúdo do Website.{' '}
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            COM QUE FINALIDADE TRATAR A IRONHACK OS DADOS PESSOAIS DO UTILIZADOR
            E DURANTE QUANTO TEMPO
          </Text2>
          <Text3 as="p">
            Os dados pessoais facilitados pelo utilizador{' '}
            <strong>através do formulário de contacto do Website</strong> serão
            tratados pela Ironhack conforme as seguintes finalidades:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Responder a questões, sugestões e reclamações que o utilizador
                comunique à Ironhack. Os seus dados serão conservados com esse
                fim durante todo o tempo da gestão da sua questão, sugestão e/ou
                reclamação e, mesmo depois, até que prescrevam as eventuais
                responsabilidades derivadas da questão.{' '}
              </li>
            </ul>
            Os dados pessoais facilitados pelo utilizador{' '}
            <strong>
              através da subscrição do boletim comercial do Website
            </strong>{' '}
            serão tratados pela Ironhack conforme as seguintes finalidades:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Efetuar a subscrição do boletim comercial para manter informado
                o Utilizador sobre as últimas notícias e eventos da Ironhack. Os
                seus dados serão conservados até que retire o consentimento
                prestado.{' '}
              </li>
            </ul>
            Os dados pessoais facilitados pelo utilizador{' '}
            <strong>
              através do formulário de inscrição para cursos da Ironhack
            </strong>{' '}
            serão tratados pela Ironhack conforme as seguintes finalidades:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Realizar a gestão da sua inscrição, bem como as ações
                necessárias para a seleção dos estudantes. Os seus dados serão
                conservados durante todo o tempo da gestão da sua inscrição e,
                mesmo depois, até que prescrevam as eventuais responsabilidades
                derivadas da inscrição.{' '}
              </li>
              <li>
                No caso de ter marcado a casa correspondente, realizar a
                subscrição do boletim comercial para o manter informado sobre as
                últimas notícias e eventos da Ironhack. Os seus dados serão
                conservados até que retire o consentimento prestado.{' '}
              </li>
            </ul>
            Os dados pessoais facilitados pelo utilizador{' '}
            <strong>
              através do formulário de inscrição como empresa colaboradora da
              Ironhack
            </strong>{' '}
            serão tratados pela Ironhack conforme as seguintes finalidades:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Realizar a inscrição da companhia para a qual trabalha como
                empresa colaboradora da Ironhack. Os seus dados serão
                conservados durante todo o tempo da gestão da empresa como
                empresa colaboradora e, mesmo depois, até que prescrevam as
                eventuais responsabilidades derivadas da inscrição.{' '}
              </li>
            </ul>
            Os dados pessoais facilitados pelo utilizador{' '}
            <strong>
              através do formulário de inscrição numa oferta de emprego
            </strong>{' '}
            serão tratados pela Ironhack conforme as seguintes finalidades:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Realizar a avaliação da candidatura para o processo de seleção
                e, sempre que se aplique, realizar as ações necessárias para a
                entrada no processo de seleção, bem como em futuros processos
                que possam ser do seu interesse. Excepto se indicado em
                contrário, é sua responsabilidade manter atualizados os
                referidos dados já que poderão ser utilizados para notificar
                ofertas da Ironhack que possam ser do seu interesse. Portanto, é
                da sua responsabilidade manter atualizados os referidos dados já
                que poderão ser utilizados para notificar ofertas da Ironhack
                que possam ser do seu interesse.{' '}
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">QUE DADOS DE CARTER PESSOAL TRATAR A IRONHACK</Text2>
          <Text3 as="p">
            Em relação ao formulário de contacto disponível no Website, a
            Ironhack tratará dos seguintes dados:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Dados de identificação: nome, apelidos, etc. </li>
              <li>
                Dados de contacto: correio eletrónico, número de telefone, etc.{' '}
              </li>
            </ul>
            Em relação ao formulário de subscrição do boletim comercial
            disponível no Website, a Ironhack tratará dos seguintes dados:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Dados de contacto: correio eletrónico, etc. </li>
            </ul>
            Em relação ao formulário de inscrição para os cursos da Ironhack
            disponível no Website, a Ironhack tratará dos seguintes dados:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Dados de identificação: nome, apelidos, etc. </li>
              <li>
                Dados de contacto: correio eletrónico, número de telefone, etc.{' '}
              </li>
            </ul>
            Em relação ao formulário de inscrição como empresa colaboradora da
            Ironhack disponível no Website, a Ironhack tratará dos seguintes
            dados:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Dados de identificação: nome, apelidos, etc. </li>
              <li>Dados de emprego: cargo. </li>
              <li>
                Dados de contacto: correio eletrónico, número de telefone, etc.{' '}
              </li>
            </ul>
            Em relação ao formulário de inscrição numa oferta de trabalho, a
            Ironhack tratará dos seguintes dados:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Dados de identificação: nome, apelidos, imagem, NIF, morada,
                etc.{' '}
              </li>
              <li>
                Dados de contacto: número de telefone, endereço de correio
                eletrónico, etc.
              </li>
              <li>
                Dados académicos e profissionais: formação/diplomas, currículo
                académico, experiência profissional, inscrição em ordens ou
                associações profissionais.
              </li>
              <li>
                Dados de detalhes de emprego: profissão, posto de trabalho,
                dados não económicos de pagamento, historial do trabalhador.
              </li>
              <li>
                Outros dados que possam ser incluídos no Curriculum Vitae.
              </li>
            </ul>
            No caso de o Utilizador facilitar dados de terceiros, manifesta ter
            o consentimento dos mesmos e compromete-se a transmitir as
            informações contidas na Política de Privacidade, eximindo a Ironhack
            de qualquer responsabilidade neste sentido. Não obstante, a Ironhack
            poderá efetuar as verificações periódicas para constatar este facto,
            adotando as medidas de diligência devida que correspondam, de acordo
            com as normas de proteção de dados.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">POLÍTICA DE COOKIES</Text2>
          <Text3 as="p">
            Clique{' '}
            <Link href="https://cdn.ironhack.com/marketing/IH_Coookies_Policy.docx">
              aqui
            </Link>{' '}
            para ver a nossa política de cookies
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            QUAL É A LEGITIMAÇO DO TRATAMENTO DOS DADOS DE CARTER PESSOAL DO
            UTILIZADOR
          </Text2>
          <Text3 as="p">
            <ul>
              <li>
                O tratamento dos dados relativo á gestão das questões, sugestões
                e/ou reclamações baseia-se no consentimento prestado pelo
                Utilizador no momento do seu pedido.{' '}
              </li>
              <li>
                O tratamento dos dados relativo á subscrição do boletim
                comercial para poder ser informado das últimas notícias e
                eventos da Ironhack baseia-se no consentimento prestado pelo
                Utilizador no momento do seu pedido.{' '}
              </li>
              <li>
                O tratamento dos dados relativo ao pedido de inscrição nos
                cursos da Ironhack baseia-se na execução da relação contratual
                entre a Ironhack e o Utilizador pelo que não precisa do
                consentimento do Utilizador.{' '}
              </li>
              <li>
                O tratamento dos dados relativo ao pedido de inscrição como
                empresa colaboradora da Ironhack baseia-se na execução da
                relação contratual pelo que não precisa do consentimento do
                Utilizador.{' '}
              </li>
              <li>
                O tratamento dos dados relativo ao pedido de inscrição das
                ofertas de trabalho baseia-se no consentimento do Utilizador.
              </li>
            </ul>
            Tenha em conta que poderá retirar o consentimento a qualquer momento
            e que a retirada do consentimento não afetará a licitude do
            tratamento realizado com antecedência à sua revogação.{' '}
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">DESTINATRIOS</Text2>
          <Text3 as="p">Os seus dados poderão ser comunicados a: </Text3>
          <Text3 as="p">
            <ul>
              <li>As Administrações públicas nos casos previstos por lei. </li>
              <li>
                Julgados e Corpos de Segurança nos casos exigidos por lei.{' '}
              </li>
              <li>
                Serviços para o processamento da informação com fins de
                marketing:
                <ul>
                  <li>Mailchimp</li>
                  <li>Pipedrive</li>
                  <li>Google Analytics</li>
                  <li>Hotjar</li>
                  <li>Facebook Custom Audience</li>
                </ul>
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">TRANSFERÊNCIAS INTERNACIONAIS</Text2>
          <Text3 as="p">
            A informação recolhida pode ser transferida para:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Ironhack campuses en Mexico, U.S. &amp; Brazil</li>
              <li>Mailchimp Inc. (U.S.)</li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">RESPONSABILIDADE DO UTILIZADOR</Text2>
          <Text3 as="p">O Utilizador:</Text3>
          <Text3 as="p">
            <ul>
              <li>
                Garantir que é maior de 18 anos e que os dados que facilita à
                Ironhack são verdadeiros, exatos, completos e atualizados. Para
                estes efeitos, o Utilizador responde pela veracidade de todos os
                dados que comunique e mantenha convenientemente atualizada a
                informação facilitada, de forma a que responda à sua situação
                real.{' '}
              </li>
              <li>
                Garantir que informou os terceiros dos quais facilitou os
                respetivos dados, no caso de o fazer, dos aspetos contidos neste
                documento. Adicionalmente, garantir que obteve a sua autorização
                para facilitar os dados à Ironhack para os fins indicados.
              </li>
              <li>
                Será responsável pelas informações falsas ou inexatas que que
                proporcione através do Website e pelos danos e prejuízos,
                diretos ou indiretos, que isso cause à Ironhack ou a terceiros.
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">COMUNICAÇES COMERCIAIS</Text2>
          <Text3 as="p">
            Uma das finalidades para as quais a Ironhack trata os dados pessoais
            proporcionados por parte dos Utilizadores é para lhes enviar
            comunicações eletrónicas com informação relativa a produtos,
            serviços, promoções, ofertas, eventos ou notícias relevantes para os
            Utilizadores. Sempre que se realize alguma comunicação deste tipo,
            esta será dirigida única e exclusivamente para os Utilizadores que
            manifestado expressamente o seu consentimento.
          </Text3>
          <Text3 as="p">
            No caso de o Utilizador deseje deixar de receber comunicações
            comerciais ou promocionais por parte da Ironhack pode solicitar o
            cancelamento do serviço enviando um e-mail para o seguinte endereço
            de correio eletrónico:{' '}
            <a href="mailto:legal@ironhack.com">legal@ironhack.com</a>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">OS SEUS DIREITOS</Text2>
          <Text3 as="p">
            O Utilizador pode entrar em contacto com a Ironhack através de uma
            carta dirigida para a morada Calle Castello, 115B, Local 7, 28006,
            Madrid, com a Referência “Protección de Datos” ou através de uma
            mensagem de correio eletrónico para o endereço{' '}
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a> juntando
            cópia do seu documento de identificação, em qualquer momento e de
            forma gratuita, para:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Revogar os consentimentos outorgados. </li>
              <li>
                Obter confirmação acerca de si na Ironhack se estão a tratar
                dados pessoais que digam respeito ao Utilizador ou não.{' '}
              </li>
              <li>Aceder aos seus dados pessoais. </li>
              <li>Retificar os dados inexatos ou incompletos. </li>
              <li>Solicitar a eliminação dos seus dados. </li>
              <li>
                Obter da Ironhack a limitação do tratamento dos dados quando se
                cumpra alguma das condições previstas nas normas de proteção de
                dados.{' '}
              </li>
              <li>
                Em determinadas circunstâncias e por motivos relacionados com a
                sua situação particular para o tratamento dos seus dados (em
                concreto, com fins comerciais por parte da Ironhack), poderá
                opor-se ao tratamento dos seus dados. A Ironhack deixará de
                tratar os dados, exceto por motivos legítimos imperiosos, ou
                pelo exercício ou defesa de possíveis reclamações.
              </li>
              <li>
                Obter intervenção humana, para expressar o seu ponto de vista e
                para impugnar as decisões automatizadas adotadas pela Ironhack.{' '}
              </li>
              <li>Solicitar a portabilidade dos seus dados. </li>
              <li>
                Interpor uma reclamação relativa à proteção dos seus dados
                pessoais perante a autoridade de controlo correspondente (no
                caso de Espanha, a Agencia Española de Protección de Datos).{' '}
              </li>
            </ul>
            O exercício dos direitos referidos ficará condicionado ao
            cumprimento dos requisitos previstos pela normativa vigente em cada
            momento para sua atenção.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">MEDIDAS DE SEGURANÇA</Text2>
          <Text3 as="p">
            A Ironhack tratará dos dados do Utilizador em todos os momentos de
            forma confidencial e guardando o pertinente dever de segredo a
            respeito dos mesmos, em conformidade com o previsto nas normas de
            aplicação, adotando para o efeito as medidas de índole técnica e
            organizativas necessárias que garantam a segurança dos seus dados e
            evitem a sua alteração, perda, tratamento ou acesso não autorizado,
            tendo em conta o estado da tecnologia, a natureza dos dados
            armazenados e os riscos aos quais estão expostos.
          </Text3>
          <Text3 as="p">Última atuação: 10/08/2018</Text3>
          <Text3 as="p">
            <strong>
              Copyright © 2018 Ironhack School S.L. Todos os direitos
              reservados.{' '}
            </strong>
          </Text3>
        </Question>
      </Layout>
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        lng: { eq: "pt" }
        ns: { regex: "/(seo)|(breadcrumbs)|(menu)|(banner)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next('pt')(PrivacyPolicy);
